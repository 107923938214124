import React from 'react'

function Solution(props) {
   return ( 
    <div id="solution" className='solution-client-container pb-44 pt-44'>
    <div className='container m-auto'>
    <div className='section-name-box pb-8 mr-auto'>
    <span className='uptitle'>Development</span>
    <h4 className='subtitle'>Solution</h4>
    </div>
    <p className='description max-w-5xl mr-auto pb-4'>{props.firstParagraph}</p>
    <p className='description max-w-5xl mr-auto pb-4'>{props.secondParagraph}</p>
    <p className='description max-w-5xl mr-auto'>{props.thirdParagraph}</p>
    </div>
    </div>
   );
}

export default Solution