import React from 'react'
import ProjectHeader from '../../Components/ProjectPage/ProjectHeader'
import AboutClient from '../../Components/ProjectPage/AboutClient'
import ClientVideo from '../../Components/ProjectPage/ClientVideo'
import Solution from '../../Components/ProjectPage/Solution'
import usedproductsImage from '../../Assets/usedproducts.png'
import usedproductsMobileImage from '../../Assets/usedproducts-mobile.png'
import usedproductsMobileVideo from '../../Assets/usedproducts-mobile-video.mp4'
import usedproductsVideo from '../../Assets/usedproducts-video.mp4'

import '../../Components/ProjectPage/projectpage.css'
import MetaTags from '../../Functions/Seo/MetaTags'
import Contact from '../../Components/Homepage/Contact/Contact'



const Baboon = () => {
  return (
    <div className='project-page'> 
    <MetaTags
      title="UsedProducts - ecommerce marketplace"
      description="Looking for an ecommerce website that's truly unique and built for success? Look no further than our latest project! We've developed a powerful online marketplace using OpenCart, complete with custom functions and APIs to ensure a seamless shopping experience. Our custom design ensures that your site stands out from the crowd and delivers the best possible customer experience. With our ecommerce expertise and cutting-edge technology, you can take your online business to the next level. Check out our site today!"
  />
    <ProjectHeader srcMobile={usedproductsMobileImage} srcDesktop={usedproductsImage} alt="Usedproducts.ro" text="Usedproducts.ro" textFunction="Web Developer" textType="eCommerce marketplace" textLocationFirst="Romania" textYear="2018 - present"/>

    <AboutClient firstParagraph="Usedproducts is an electronics and gadgets retailer that entered the online market in 2018. Starting with a relatively small eCommerce store of only around 1,000 products, they quickly realized the importance of having a strong online presence and began investing in the development of their website. Over the past four years, we have continuously worked on the website, implementing various custom functions and features. Today, the website has evolved into a fully-featured ecommerce platform with a marketplace, APIs for the billing program, and an impressive inventory of over 15,000 products." 
    
    />
    <ClientVideo srcMobile={usedproductsMobileVideo} srcDesktop={usedproductsVideo}/>
    <Solution firstParagraph="Over the past four years, we have worked closely with Usedproducts to continuously improve their website and online shopping experience. We have implemented a range of custom functions and features, including a marketplace where third-party vendors can sell their products, APIs for the billing program, and an easy-to-use checkout process." 
    
    secondParagraph="Thanks to our ongoing work with Usedproducts, they have been able to establish a strong online presence and reach a wider audience. Their website has become a leading destination for customers looking to buy electronics and gadgets online, and they continue to see steady growth and success in the digital marketplace."

    />
    <Contact />

    </div>
  )
}

export default Baboon