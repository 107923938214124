import React from 'react'
import './contact.css'
import Contactform from './ContactForm/Contactform'
import contactpicture from '../../../Assets/contact-pic.jpeg'
import { IoLogoWhatsapp } from 'react-icons/io'
import { BsMessenger } from 'react-icons/bs'
import { BsFillEnvelopeFill } from 'react-icons/bs'

const Contact = () => {
  return (
    <div id="contact" className='contact bg-dark pb-24 pt-24'>
    <div className='container m-auto'>
    <div className='grid lg:grid-cols-2 lg:gap-4 lg:items-center pb-12 pt-12'>
    <div className='ml-auto contact-form-container'>
    <h2 className='text-light'>Let's start a project together</h2>
    <Contactform />
    </div>

    <div className='lg:w-1/3 m-auto contact-info-container'>
    <img className='rounded-full w-2/5 mb-16' src={contactpicture}></img>
    <div className='contact-details'>
    <span className='title text-light uppercase text-sm relative bottom-8'>contact details</span>


    <article className="contact__option text-light pb-5">
            
            <h4 className='font-bold'>Email</h4>
            <a className='flex items-center' href="mailto:vladdcovaci@gmail.com" target="_blank"> <BsFillEnvelopeFill className='icon-contact mr-3'/> vladdcovaci@gmail.com </a>
    </article>
          
    <article className="contact__option text-light pb-5">
            <h4 className='font-bold'>Messenger</h4>
            <a className='flex items-center' href="https://m.me/100010576512797/" target="_blank"> <BsMessenger className='icon-contact mr-3'/> Send a message </a>
    </article>
          
    <article className="contact__option text-light pb-5">
            <h4 className='font-bold'>WhatsApp</h4>
            <a className='flex items-center' href="https://wa.me/40754390076" target="_blank"> <IoLogoWhatsapp className='icon-contact mr-3'/> +4 0754 390 076 </a>
            
    </article>


    </div>
    </div>
    </div>
    
    
    </div>
    </div>
  )
}

export default Contact