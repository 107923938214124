import React from 'react'

function AboutClient(props) {
   return ( 
    <div className='about-client-container pb-44 pt-44'>
    <div className='container m-auto'>
    <div className='section-name-box pb-8 lg:ml-auto lg:text-right'>
    <span className='uptitle'>Context</span>
    <h4 className='subtitle'>About the Client</h4>
    </div>
    <p className='description max-w-5xl ml-auto'>{props.firstParagraph}</p>
    <p className='description max-w-5xl ml-auto pt-4'>{props.secondParagraph}</p>
    <p className='description max-w-5xl ml-auto pt-4 text-2xl'><a href="#solution"> <span class="font-bold">{props.question}</span></a> </p>
    </div>
    </div>
   );
}

export default AboutClient