import React from 'react'
import { motion } from "framer-motion"
import { useEffect } from 'react';

function ProjectHeader(props) {

    useEffect(() => {
        document.body.classList.add('case-study');
        return () => {
          document.body.classList.remove('case-study');
        };
      }, []);

    return (
        <div className='projectHeader'>
        <div className='container m-auto'>
        <div className='lg:max-w-5xl m-auto pt-44 pb-5'>
        <h1 className='project-title font-extrabold'> {props.text} </h1>
        </div>
        <div className='lg:max-w-5xl pb-32 m-auto mobile-project-header'>
        <div className='lg:grid lg:grid-cols-4 lg:gap-32 lg:items-center container m-auto'>
        <div className='box'>
        <span className='uptitle'> Role </span>
        <h4 className='subtitle-border'> {props.textFunction} </h4>
        </div>
        <div className='box'>
        <span className='uptitle'> Type </span>
        <h4 className='subtitle-border'> {props.textType} </h4>
        </div>
        <div className='box'>
        <span className='uptitle'> Location </span>
        <h4 className='subtitle subtitle-border'> {props.textLocationFirst} </h4>
        <h4 className='subtitle'> {props.textLocationSecond} </h4>
        <h4 className='subtitle'> {props.textLocationThird} </h4>
        <h4 className='subtitle'> {props.textLocationForth} </h4>
        <h4 className='subtitle'> {props.textLocationFifth} </h4>
        </div>
        <div className='box'>
        <span className='uptitle'> Year </span>
        <h4 className='subtitle-border'> {props.textYear} </h4>
        </div>
        </div>
        </div>

        <motion.div
        animate={{ x: 0 }}
        transition={{ delay: 6 }} 
        >

        <img className='project-img mobile rounded-def' src={props.srcMobile} alt={props.alt} />
        <img className='project-img desktop rounded-def' src={props.srcDesktop} alt={props.alt} />
        </motion.div>
        </div>
        </div>


    );
}

export default ProjectHeader