import React from 'react'
import './footer.css'
import Socialmedia from '../../Components/Global/Socialmedia/Socialmedia'

const Footer = () => {
  return (
    <div className='footer-container m-auto p-biggap text-center rounded-def'>
    <p className='copyright p-smallgapbottom'>@ 2023 All right reserved vladcovaci.com</p>
    <Socialmedia />

    </div>
  )
}

export default Footer