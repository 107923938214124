import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './navigation.css';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    const burgerMenu = document.querySelector('.burger-menu');
    if (burgerMenu) {
      burgerMenu.classList.toggle('open');
    }
  };

  return (
    <>
      <div className="burger-menu" onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <motion.ul
        className={`menu ${isOpen ? 'open' : ''}`}
        initial={{ x: '100%' }}
        animate={{ x: isOpen ? 0 : '100%' }}
        transition={{ duration: 0.5 }}
      >
        <li><a href="/#work"><span>Work</span></a></li>
        <li><a href="/#about"><span>About</span></a></li>
        <li><a href="/#contact"><span>Contact</span></a></li>
      </motion.ul>

      <ul className='flex menu-desktop'>
    <li><a href="/#work"><span>Work</span></a></li>
    <li><a href="/#about"><span>About</span></a></li>
    <li><a href="/#contact"><span className='pr-0'>Contact</span></a></li>
    </ul>
    </>
  );
};

export default Navigation;
