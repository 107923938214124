import React, { useRef, useEffect } from 'react';

function ClientVideo(props) {
  const mobileVideoRef = useRef(null);
  const desktopVideoRef = useRef(null);

  useEffect(() => {
    const desktopVideoElement = desktopVideoRef.current;

    const isInViewport = (element) => {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    if (isInViewport(desktopVideoElement)) {
      desktopVideoElement.play();
    }

    const handleScroll = () => {
      if (isInViewport(desktopVideoElement)) {
        desktopVideoElement.play();
      } else {
        desktopVideoElement.pause();
      }
    };

    desktopVideoElement.addEventListener('loadeddata', handleScroll);
    window.addEventListener('scroll', handleScroll);

    return () => {
      desktopVideoElement.removeEventListener('loadeddata', handleScroll);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [props.srcDesktop]);

  useEffect(() => {
    const mobileVideoElement = mobileVideoRef.current;

    const isInViewport = (element) => {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    if (isInViewport(mobileVideoElement)) {
      mobileVideoElement.play();
    }

    const handleScroll = () => {
      if (isInViewport(mobileVideoElement)) {
        mobileVideoElement.play();
      } else {
        mobileVideoElement.pause();
      }
    };

    mobileVideoElement.addEventListener('loadeddata', handleScroll);
    window.addEventListener('scroll', handleScroll);

    return () => {
      mobileVideoElement.removeEventListener('loadeddata', handleScroll);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [props.srcMobile]);

  return (
    <div className='video-client-container pb-44 pt-44 bg-light rounded-t-def'>
      <div className='container m-auto video-client-background'>
        <video className='project-video mobile rounded-t-def' src={props.srcMobile} playsInline muted ref={mobileVideoRef}/>
        <video className='project-video desktop rounded-t-def' src={props.srcDesktop} playsInline muted ref={desktopVideoRef}/>
      </div>
    </div>
  );
}

export default ClientVideo;
