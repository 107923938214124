import React from 'react'
import CountUp from 'react-countup';
import { useState } from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import './counters.css'

const Counters = () => {
    const [counterOn, setCounterOn] = useState(false);

  return (
    <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
    <div className='counters bg-light rounded-def mb-8'>
    <div className='lg:grid lg:grid-cols-3 lg:gap-4 lg:items-center container m-auto'>

    <div className='counter-box text-center'>
    <h2 className='font-bold'>{counterOn && <CountUp start={0} end={53} duration={3} delay={1} />}</h2>
    <h4>Projects</h4>
    </div>
 
    <div className='counter-box text-center'>
    <h2 className='font-bold'>{counterOn && <CountUp start={0} end={68} duration={3} delay={1} />}</h2>
    <h4>Clients</h4>
    </div>

    <div className='counter-box text-center'>
    <h2 className='font-bold'>{counterOn && <CountUp start={0} end={5} duration={3} delay={1} />} + </h2>
    <h4>Years</h4>
    </div>
    
    </div>
    </div>
    </ScrollTrigger>
  )
}

export default Counters