import './Style/index.css';
import './Style/mobile.css';
import Header from './Layouts/Header/Header';
import Footer from './Layouts/Footer/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './Pages/Homepage/Homepage';
import Baboon from './Pages/Baboon/Baboon';
import Usedproducts from './Pages/Usedproducts/Usedproducts';
import Techcon from './Pages/Techcon/Techcon';
import Preloader from './Functions/Preloader/Preloader';
import { motion } from 'framer-motion';
import { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion"

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timeout);
  }, []);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.pageYOffset > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="app-container flex flex-col h-screen justify-between">
      <AnimatePresence>
        {loading ? (
          <motion.div key='loader'>
            <Preloader />
          </motion.div>
        ) : (
            <>
            <motion.header className={`header rounded-def ${isSticky ? ' header-sticky' : ''}`} key='header' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1 }}>
              <Header />
            </motion.header>

            <div className='main-container'>
              <motion.main key='main' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.5 }} className='main'>
                <Routes>
                  <Route path="/" element={<Homepage key="homepage"/>} />
                  <Route path="/baboon" element={<Baboon key="baboon"/>} />
                  <Route path="/usedproducts" element={<Usedproducts key="usedproducts"/>} />
                  <Route path="/techcon" element={<Techcon key="techon"/>} />
                </Routes>
              </motion.main>
            </div>

            <motion.footer key='footer' initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2 }}>
              <Footer />
            </motion.footer>
            </>
        )}
      </AnimatePresence>
    </div>
  );
}

export default App;
