import React from 'react'
import './logo.css'
import {Link} from 'react-router-dom'


const Logo = () => {
  return (
    <div className='logo uppercase font-medium'>
    <Link to="/"><span>Vlad Covaci</span></Link>
    </div>
  )
}

export default Logo