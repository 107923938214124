import React from 'react'
import ProjectHeader from '../../Components/ProjectPage/ProjectHeader'
import AboutClient from '../../Components/ProjectPage/AboutClient'
import ClientVideo from '../../Components/ProjectPage/ClientVideo'
import Solution from '../../Components/ProjectPage/Solution'
import baboonImage from '../../Assets/baboon.png'
import baboonMobileImage from '../../Assets/baboon-mobile.png'
import baboonMobileVideo from '../../Assets/baboon-mobile-video.mp4'
import baboonVideo from '../../Assets/baboon-video.mp4'
import '../../Components/ProjectPage/projectpage.css'
import MetaTags from '../../Functions/Seo/MetaTags'
import Contact from '../../Components/Homepage/Contact/Contact'


const Baboon = () => {
  return (
    <div className='project-page'>
    <MetaTags
      title="Baboon.ro - presentation website"
      description="Looking for a standout presentation website that was created with care and expertise? Look no further than our latest project! We developed a custom-coded website that runs on the powerful WordPress platform. Unlike many other websites, we avoided using website builders, opting instead for custom code on each page for maximum speed and functionality. Experience the quality and craftsmanship of a bespoke website today!"
  />
    <ProjectHeader srcMobile={baboonMobileImage} srcDesktop={baboonImage} alt="Baboon" text="Baboon.ro" textFunction="Web Developer" textType="WordPress custom" textLocationFirst="Romania" textYear="2021"/>

    
    <AboutClient firstParagraph="in 2020, during the pandemic, we faced the challenge of reinventing ourselves. To help Baboon stand out in a highly competitive market, we decided to redesign our website. This project was undertaken during a period of rebranding, which meant we had to create a site that not only looked modern and visually appealing but also aligned with the company's new brand identity." question="But how?"/>

    <ClientVideo srcMobile={baboonMobileVideo} srcDesktop={baboonVideo}/>

    <Solution firstParagraph="Our team's approach was to build a site that prioritized user experience and showcased Baboon's services in a clear and concise way. We achieved this by working closely with the company's stakeholders to understand their business goals and objectives. Additionally, we researched the latest web design trends and technologies to ensure the site was up-to-date and highly functional."

    secondParagraph="To achieve fast load times, we decided to develop the website on WordPress with custom pages, avoiding the use of pre-built page builders. This allowed us to create a highly optimized website that loaded quickly, up to 5 times faster than a typical website."
    
    thirdParagraph="The result was a modern and responsive website that not only met Baboon's needs but also impressed their clients. The new site highlighted Baboon's unique selling points and helped to establish the company as a leader in their industry. Overall, this project was a huge success and demonstrated our team's ability to deliver high-quality work even in challenging circumstances." />

    <Contact />
    
    </div>
  )
}

export default Baboon