import React from 'react'
import Logo from '../../Components/Logo/Logo'
import Navigation from '../Navigation/Navigation'
import './header.css'

const Header = () => {
  return (
    <div className='flex justify-between	m-auto sticky top-0 container'>
    <div className='logo-container'><Logo /></div>
    <div className='navigation-container'><Navigation /></div>
    </div>
  )
}

export default Header