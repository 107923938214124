import React, { useState, useEffect } from 'react';
import './projectboxes.css'
import baboonImage from '../../../../Assets/baboon.png';
import { motion } from "framer-motion";


function BaboonBox() {
  const [hovered, setHovered] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const imageBoxRef = React.useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const imageBox = imageBoxRef.current;
    if (hovered && imageBox) {
      const halfWidth = imageBox.offsetWidth / 2;
      const leftPosition = Math.max(0, Math.min(window.innerWidth - imageBox.offsetWidth, cursorPosition.x - halfWidth));
      imageBox.style.left = `${leftPosition}px`;
    }
  }, [hovered, cursorPosition]);

  return (
    <div
      className="baboon projectbox"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{ position: 'relative', width: '100%', height: '100%' }}>

      <div className="project-row">
        <div className="lg:grid lg:grid-cols-2 lg:gap-4 lg:items-center pb-24 pt-24">
          <div className="project-name">
            <h3>Baboon Software</h3>
          </div>
          <div className="project-type lg:w-1/4 lg:ml-auto">
            <span>WordPress custom</span>
          </div>
        </div>
      </div>
      {hovered && (
        <motion.div className='project-row-hovered' ref={imageBoxRef}
          initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 50 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ ease: 'easeOut', duration: 0.5 }}
        
        >
          <motion.img
            src={baboonImage}
            alt="Baboon"
            style={{
             
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              zIndex: 1,
             
            }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ ease: 'easeOut', duration: 0.5 }}
          />
        </motion.div>
      )}
    </div>
  );
}

export default BaboonBox;
