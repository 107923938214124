import React from 'react'
import './socialmedia.css'
import { RiLinkedinFill } from 'react-icons/ri'
import { IoLogoWhatsapp } from 'react-icons/io'
import { BsMessenger } from 'react-icons/bs'
import { SiTwitter } from 'react-icons/si'
import { HiDocumentText } from 'react-icons/hi'
import CV from '../../../Assets/resume.pdf'


const Socialmedia = () => {
  return (
    <div className='social-media-container flex justify-center'>
    <a className='linkedin' href="https://www.linkedin.com/in/vladcovaci/"><RiLinkedinFill /></a>
    <a className='twitter' href="https://wa.me/40754390076" target="_blank"><SiTwitter /></a>
    <a className='messenger' href="https://m.me/100010576512797/" target="_blank"><BsMessenger /></a>
    <a className='whatsapp' href="https://wa.me/40754390076" target="_blank"><IoLogoWhatsapp /></a>
    <a className='resume' href={CV} target="_blank"><HiDocumentText /></a>

    </div>
  )
}

export default Socialmedia