import React from 'react'
import './about.css'
import Socialmedia from '../../Global/Socialmedia/Socialmedia'
import { BsWordpress } from 'react-icons/bs'
import { FaOpencart } from 'react-icons/fa'
import { FaMagento } from 'react-icons/fa'
import { AiFillHtml5 } from 'react-icons/ai'
import { IoLogoJavascript } from 'react-icons/io'
import { SiTailwindcss } from 'react-icons/si'
import { FaReact } from 'react-icons/fa'
import { FaPhp } from 'react-icons/fa'
import { FaShopify } from 'react-icons/fa'


const About = () => {
  return (
    <div id="about" className='about bg-light rounded-def mb-8'>
    <div className='lg:grid lg:grid-cols-2 lg:gap-4 lg:items-center container m-auto'>
    <div>
    <h2>Professional Background and Expertise</h2>
    <p>I am a frontend developer with over 5 years of experience, specializing in WordPress, OpenCart, Magento2, Shopify, and React. I have completed over 53 projects and have built strong relationships with more than 68 satisfied clients. In addition to my development work, I have served as a project manager where I was responsible for planning and allocating resources, preparing budgets, monitoring progress, and keeping stakeholders informed throughout the project lifecycle. With a passion for staying up-to-date with the latest trends and technologies in frontend development, I am committed to bringing the best practices and innovations to every project I work on.</p>
    <div><Socialmedia /></div>
    </div>
    
    <div className='lg:grid lg:grid-cols-3 lg:gap-4 lg:items-center grid grid-cols-3 gap-4 items-center container m-auto logo-section'>
    <div className='logo-technology'><BsWordpress /> </div>
    <div className='logo-technology'><FaOpencart /> </div>
    <div className='logo-technology'><FaMagento /> </div>

    <div className='logo-technology'><AiFillHtml5 /> </div>
    <div className='logo-technology'><IoLogoJavascript /> </div>
    <div className='logo-technology'><SiTailwindcss /> </div>

    <div className='logo-technology'><FaReact /> </div>
    <div className='logo-technology'><FaPhp /> </div>
    <div className='logo-technology'><FaShopify /> </div>
   
    </div>
    
    </div>
    </div>
  )
}

export default About