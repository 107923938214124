import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import './preloader.css'

const Preloader = () => {
  

  return (
    <motion.div
      className="preloader-container"
      initial={{ opacity: 0, y: -300 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      exit={{ x: -300, opacity: 0 }}
      
      
    >
      <div className="boxanimation-wrapper">
        <motion.div
          className="boxanimation"
          animate={{
            scale: [1, 2, 2, 1, 1],
            rotate: [0, 0, 180, 180, 0],
            borderRadius: ["0%", "0%", "50%", "50%", "0%"]
          }}
          transition={{
            duration: 4,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 2
          }}
        />
      </div>
      <h2 className="font-thin preloader-text">vladcovaci.com</h2>
    </motion.div>
  );
};

export default Preloader;
