import React from 'react'
import './herosection.css'
import Socialmedia from '../../Global/Socialmedia/Socialmedia'
import MouseTrail from '../../../Functions/MouseTrail/MouseTrail'

const Herosection = () => {
  return (
    <div className ='herosction-container bg-light flex flex-col justify-end rounded-def'>
    <div className ='herosection rounded-b-def'>
    <div className = 'herosection-container container m-auto'>
    <div className='lg:grid lg:grid-cols-2 lg:gap-4 lg:items-center pb-12'>
    <div>
    <h1>Project Manager.<br></br><span class="delimiting"></span>Frontend Developer.</h1>
    </div>
    <div className='lg:w-4/6 lg:ml-auto'><p> I create immersive solutions that help clients achieve their goals and enhance user engagement, utilizing my skills in design, motion graphics, and cutting-edge web technologies.</p></div>
    
    </div>
    </div>
    </div>

    
    <MouseTrail />
    </div>
  )
}

export default Herosection