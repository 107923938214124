import React from 'react'
import './projects.css'
import BaboonBox from './ProjectBoxes/BaboonBox'
import UsedProducts from './ProjectBoxes/Usedproducts'
import TechconBox from './ProjectBoxes/Techcon'
import { Link } from 'react-router-dom'

const Projects = () => {
  return (
   <div id="work" className='projects bg-white rounded-def'>
   <div className='container m-auto'>
   
    <Link to="/baboon"> <BaboonBox /> </Link>
    <Link to="/usedproducts"> <UsedProducts /> </Link>
    <Link to="/techcon"> <TechconBox /> </Link>

    </div>
   </div>
  )
}

export default Projects