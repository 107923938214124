import React from 'react'
import { useEffect } from 'react'
import Herosection from '../../Components/Homepage/Herosection/Herosection'
import About from '../../Components/Homepage/About/About'
import Projects from '../../Components/Homepage/Projects/Projects'
import Contact from '../../Components/Homepage/Contact/Contact'
import Counters from '../../Components/Global/Counters/Counters'
import MetaTags from '../../Functions/Seo/MetaTags'

const Homepage = () => {

  useEffect(() => {
    document.body.classList.add('home');
    return () => {
      document.body.classList.remove('home');
    };
  }, []);



  return (
    <div className='homepage-container'>
    <MetaTags
      title="Vlad Covaci - Frontend Developer / Project Manager"
      description="From coding to collaboration, see how I can bring your digital projects to life. Visit my portfolio today!"
  />
      <Herosection />
      <About />
      <Counters />
      <Projects />
      <Contact />
    
    </div>
  )
}

export default Homepage