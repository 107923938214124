import React, { useState, useEffect } from 'react';
import './mousetrail.css';

const MouseTrail = () => {
  const [positions, setPositions] = useState([]);

  const addPosition = (event) => {
    const newPosition = {
      x: event.clientX,
      y: event.clientY
    };
    setPositions(prevPositions => [...prevPositions, newPosition].slice(-30));
  };

  const clearPositions = () => {
    setPositions([]);
  };

  useEffect(() => {
    const container = document.querySelector('.herosction-container');
    container.addEventListener('mousemove', addPosition);
    container.addEventListener('mouseleave', clearPositions);
    container.addEventListener('mouseenter', clearPositions);
    return () => {
      container.removeEventListener('mousemove', addPosition);
      container.removeEventListener('mouseleave', clearPositions);
      container.removeEventListener('mouseenter', clearPositions);
    };
  }, []);

  return (
    <div className="mouse-trail">
      {positions.map((position, index) => (
        <div
          key={index}
          className="trail"
          style={{
            left: position.x,
            top: position.y,
            width: '120px',
            height: '120px',
            borderRadius: '50%',
            background: `linear-gradient(to bottom right, #02AABD, #00CDAC)`,
            transition: 'transform 0.7s ease'
          }}
        />
      ))}
    </div>
  );
};

export default MouseTrail;
