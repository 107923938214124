import React from 'react'
import ProjectHeader from '../../Components/ProjectPage/ProjectHeader'
import AboutClient from '../../Components/ProjectPage/AboutClient'
import ClientVideo from '../../Components/ProjectPage/ClientVideo'
import Solution from '../../Components/ProjectPage/Solution'
import techconImage from '../../Assets/techcon.png'
import techconMobileImage from '../../Assets/techcon-mobile.png'
import techconMobileVideo from '../../Assets/techcon-mobile-video.mp4'
import '../../Components/ProjectPage/projectpage.css'
import techconVideo from '../../Assets/techcon-video.mp4'
import MetaTags from '../../Functions/Seo/MetaTags'
import Contact from '../../Components/Homepage/Contact/Contact'



const Baboon = () => {
  return (
    <div className='project-page'>
    <MetaTags
      title="Tech-con.ro - wordpress multisite"
      description="Looking for a comprehensive and efficient website that serves multiple regions? Check out our latest project! We developed a WordPress website using the multisite function to provide tailored experiences for five individual countries. Each version of the site features localized content, language, and design to best serve our users' needs. Experience the power and flexibility of a multisite WordPress website today!"
  />
    <ProjectHeader srcMobile={techconMobileImage} srcDesktop={techconImage} alt="Techcon.ro" text="Tech-con.ro" textFunction="Web Developer Project Mangaer" textType="WordPress multisite" textLocationFirst="Romania" textLocationSecond="Poland" textLocationThird="Czech" textLocationForth="Serbia" textLocationFifth="Hungary" textYear="2022"/>
    
    <AboutClient firstParagraph="Tech-con is a multinational company with offices in five different countries: Romania, Czech, Serbia, Hungary, and Poland. As part of their expansion strategy, they needed a presentation website that could showcase their services and reach a wider audience. The challenge was that the website needed to include information about all the countries in which they have offices, and each country had over 500 pages (almost 1000 pages per website when including the English translations). This was a significant undertaking that took over 12 months to complete." 
     
    secondParagraph="The most difficult requirement was that the client wanted a single website to encompass all five countries, each with its unique content and language while also maintaining the same database." 
    
    question="Want to know how we achieved this?"/>
    
    <ClientVideo srcMobile={techconMobileVideo} srcDesktop={techconVideo}/>
    
    <Solution firstParagraph="To meet the client's requirement for a single website that would encompass all five countries, each with its unique content and language, we employed a multi-site network within WordPress. We created a primary site to serve as the core, and designed individual sub-sites for each country with their respective content and language. This approach allowed us to have a single database, while giving each country's sub-site its own distinct identity." />
    <Contact />
    </div>
  )
}

export default Baboon