import React from 'react'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './contactform.css';

const Contactform = () => {

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_zivi1ik', 'template_kjoyozo', form.current, 'ZAd6-xbBXeagRItu0')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset()
  };


  return (
    <form ref={form} onSubmit={sendEmail}>
    <div className='input-row'> <input className='w-full bg bg-transparent mb-8 text-light' type="text" name='name' placeholder='Your Name' required/> </div>
    <div className='input-row'> <input className='w-full bg bg-transparent mb-8 text-light' type="email" name='email' placeholder='Your Email' required/> </div>
    <div className='input-row'> <textarea className='w-full bg bg-transparent mb-8 text-light' name='message' rows="7" placeholder='Your Message' required></textarea> </div>
    <button type='submit' className='bg-light text-dark py-3 px-8 rounded-def font-bold'>Send Message</button>
  </form>
  )
}

export default Contactform